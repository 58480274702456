<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  setup() {
    
  },
};
</script>

<style lang="scss">
#app {
  min-height: 100%;
  position: relative;
}
</style>
